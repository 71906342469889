



































import { PropType } from 'vue';
import { hexColor } from '@/helpers/validation';
import * as vr from '@/helpers/validation';

type TColorPickerValidation = (v: string) => boolean | string;

type TColorPickerState = {
  showPicker: boolean;
};

export default {
  name: 'ColorPicker',
  props: {
    value: {
      type: String as PropType<string>,
      default: ''
    },
    label: {
      type: String as PropType<string>,
      default: ''
    },
    outlined: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => true
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    disabled: {
      type: Boolean,
      default: (): boolean => false
    }
  },
  data(): TColorPickerState {
    return {
      showPicker: false
    };
  },
  computed: {
    rules(): TColorPickerValidation[] {
      const rules = [hexColor];

      if (this.required) {
        rules.unshift(vr.required);
      }

      return rules;
    },
    color: {
      get(): string {
        return this.value;
      },
      set(value: string): void {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    setColor(color: string, isPicker: boolean): void {
      if (isPicker) {
        this.color = color.toLowerCase();
        return;
      }
      this.color = color;
    }
  }
};
