









export default {
  name: 'FAQListItem',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    panelState: {
      get(): number | undefined {
        return this.value;
      },
      set(v: number | undefined): void {
        this.$emit('input', v);
      }
    }
  }
};
