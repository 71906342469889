































import { mapGetters } from 'vuex';

export default {
  name: 'ExpansionProgressPanel',
  props: {
    time: {
      type: String
    },
    completed: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: undefined
    },
    scrollIntoView: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('app', ['isCopyStakeRole']),
    panelState: {
      get(): number | undefined {
        return this.value;
      },
      set(v: number | undefined): void {
        this.$emit('input', v);
      }
    },
    showProgressIcon(): boolean {
      return !this.isCopyStakeRole && this.completed;
    },
    showProgressLine(): boolean {
      return (
        !this.isCopyStakeRole &&
        this.progress &&
        (typeof this.value === 'number' || this.completed)
      );
    }
  },
  watch: {
    value(isActive: boolean): void {
      if (this.scrollIntoView && isActive !== undefined) {
        setTimeout(
          () =>
            this.$vuetify.goTo(this.$el, {
              duration: 300,
              easing: 'easeInOutCubic',
              offset: 80
            }),
          300
        );
      }
    }
  }
};
